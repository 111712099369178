import React, { useEffect, useState } from 'react';
import EventCard from './EventCard';
import { format } from 'date-fns';

const Home = () => {
    const [events, setEvents] = useState([]);
    const [organizers, setOrganizers] = useState([]);
    const [communityLinks, setCommunityLinks] = useState([]);

    useEffect(() => {
        // Fetch events
        fetch('/data/events.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setEvents(data.events))
            .catch(error => console.error('Error fetching events:', error));

        // Fetch organizers and community links
        fetch('/data/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setOrganizers(data.organizers);
                setCommunityLinks(data.communityLinks);
            })
            .catch(error => console.error('Error fetching organizers and community links:', error));
    }, []);

    const today = new Date();

    // Separate events into upcoming and past
    const upcomingEvents = events.filter(event => new Date(event.date) >= today);
    const pastEvents = events.filter(event => new Date(event.date) < today);

    return (
        <div>
            <h2>Upcoming Events</h2>
            <div className="event-list">
                {upcomingEvents.length > 0 ? (
                    upcomingEvents.map(event => (
                        <EventCard key={event.id} event={event} />
                    ))
                ) : (
                    <p>No upcoming events.</p>
                )}
            </div>

            <h2>Past Events</h2>
            <div className="event-list">
                {pastEvents.length > 0 ? (
                    pastEvents.map(event => (
                        <EventCard key={event.id} event={event} />
                    ))
                ) : (
                    <p>No past events.</p>
                )}
            </div>

            <h2>Organizers</h2>
            <ul>
                {organizers.map(organizer => (
                    <li key={organizer.name}>
                        <a href={organizer.link} target="_blank" rel="noopener noreferrer">
                            {organizer.name}
                        </a> - {organizer.role}
                    </li>
                ))}
            </ul>

            <h2>Community Links</h2>
            <ul>
                {communityLinks.map(link => (
                    <li key={link.name}>
                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                            {link.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Home;