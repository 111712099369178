import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home'; // Your home component
import EventTemplate from './components/EventTemplate'; // Component to display event details

const App = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            const response = await fetch('/data/events.json'); // Fetch from public/data
            const data = await response.json();
            setEvents(data.events);
        };

        fetchEvents();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home events={events} />} />
                <Route path="/:slug" element={<EventTemplate events={events} />} />
            </Routes>
        </Router>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));

export default App; // Ensure this line is present