import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';

const EventTemplate = ({ events }) => {
    const { slug } = useParams(); // Get the slug from the URL
    const [event, setEvent] = useState(null);

    useEffect(() => {
        // Find the event based on the slug
        const foundEvent = events.find(e => e.slug === slug);
        setEvent(foundEvent);
    }, [slug, events]);

    if (!event) return <p>Loading...</p>;

    const dateTimeString = `${event.date}T${event.time}`; // Ensure this is valid
    const dateTime = new Date(dateTimeString);

    if (isNaN(dateTime)) {
        console.error('Invalid date:', dateTimeString);
        return <p>Invalid date/time</p>; // Handle invalid date
    }

    return (
        <div className="event-details">
            <Link to="/" className="back-link">Back</Link> {/* Back link to home */}
            <h2>{event.title}</h2>
            <p>
                {format(dateTime, "MMMM do yyyy h:mm a")}
            </p>
            <p>Location: {event.location}</p>
            <p>Description: {event.description}</p>
            <a href={event.link} target="_blank" rel="noopener noreferrer" className="cta-button">More Info</a>
        </div>
    );
};

export default EventTemplate;