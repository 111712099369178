import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const EventCard = ({ event }) => {
    // Combine date and time for formatting
    const dateTimeString = `${event.date}T${event.time}`;
    const formattedDateTime = format(new Date(dateTimeString), "MMMM do yyyy h:mm a");

    return (
        <div className="event-card">
            {event.useLandingPage ? (
                <Link to={`/${event.slug}`}>
                    <h3>{event.title}</h3>
                </Link>
            ) : (
                <a href={event.link} target="_blank" rel="noopener noreferrer">
                    <h3>{event.title}</h3>
                </a>
            )}
            <p>Date and Time: {formattedDateTime}</p>
            <p>Location: {event.location}</p>
        </div>
    );
};

export default EventCard;